<template>
  <div>
    <v-container class="container--fluid grid-list-md text-center">
      <h1>Payment Gateway Dashboard</h1>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
};
</script>
